<template>
    <div id="app">
        <div class="cover" v-if="selected === 'main'">
            <img class="logo" src="@/assets/logo.png" alt="">
            <button @click="selected = 'new'">Новое меню</button>
            <button @click="selected = 'new_eng'">New menu</button>
            <button @click="selected = 'ru'">Русский</button>
            <button @click="selected = 'eng'">English</button>
        </div>
        <div class="cover no-flex" v-else>
            <button class="back" @click="selected = 'main'">
                <svg class="a-right" fill="white" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     viewBox="0 0 490 490" xml:space="preserve">
                    <g>
	                    <g>
	                    	<polygon points="332.668,490 82.631,244.996 332.668,0 407.369,76.493 235.402,244.996 407.369,413.507"/>
	                    </g>
                    </g>
                </svg>
            </button>

            <img v-for="number in categoryCount[selected]" class="menu-image" :src="getImgUrl(`${selected}_${number}`)" alt="">
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    data: () => ({
        categoryCount: {
            eng: 6,
            new: 6,
            new_eng: 6,
            ru: 6,
        },
        selected: 'main'
    }),

    methods: {
        getImgUrl(name) {
            let images = require.context('./assets/', false, /\.webp$/)
            return images('./' + name + ".webp")
        }
    },

    mounted() {
        let fsize = window.innerWidth * 16 / 425
        document.querySelector('html').style.fontSize = fsize + 'px'
    }
}
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
    color: #333;
}

.cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
    background: #333333;

    &.no-flex {
        display: block;
    }

    .logo {
        width: 60%;
        margin: 1rem;
    }

    .menu-image {
        width: 100%;
    }

    button {
        margin:  .5rem 1rem;
        padding: 1rem;
        width: 60%;
        border-radius: 10px;
        border: 1px solid #dbbf35;
        text-transform: uppercase;
        color: #dbbf35;
        background: #141d26;

        &:hover, &:focus {
            color: #141d26;
            background: #dbbf35;
        }

        &.back {
            border-radius: 50%;
            width: 4rem;
            height: 4rem;
            position: fixed;
            top: 0;
            left: 0;

            .a-right {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
